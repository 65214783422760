<template>
    <div class="bg-white shadow-lg rounded-lg mr-3 ml-3 pb-5 mt-5 relative">
        <LoadingComponent :isLoading="loading" />
        <div class="content">
            <div class="top-content">
                <div class="title text-black-blod-18">
                    教戰守則資訊 <button class="orange-btn-outline-800 w-[100px] h-[30px] text-sm mr-5" @click="deleteItem">刪除</button>
                </div>
                <div class="inputs">
                    <div class="input">
                        <div class="label">內容</div>
                        <el-input
                            v-model="inputs.text"
                            maxlength="30"
                            show-word-limit
                            type="textarea"
                            :autosize="{ minRows: 1 }"
                            class="w-full"></el-input>
                    </div>
                    <div class="input">
                        <div class="label">使用期間</div>
                        <el-select v-model="duration" class="w-full" placeholder="請選擇" @change="changeDuration">
                            <el-option v-for="(item, idx) in duringOption" :key="idx" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">連結</div>
                        <el-input v-model="inputs.url" class="w-full"></el-input>
                    </div>

                    <div class="input">
                        <div class="label">期間範圍</div>
                        <div class="flex items-center">
                            <el-date-picker
                                v-model="inputs.start_date"
                                type="date"
                                placeholder="選擇開始日期"
                                value-format="yyyy-MM-dd"
                                :picker-options="startDateOptions"
                                :disabled="duration == false">
                            </el-date-picker>
                            <span class="mx-5">至</span>
                            <el-date-picker
                                v-model="inputs.end_date"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="選擇結束日期"
                                :picker-options="endDateOptions"
                                :disabled="duration == false">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="input">
                        <div class="label">分類</div>
                        <el-select v-model="inputs.category" class="w-full" placeholder="請選擇">
                            <el-option v-for="(item, idx) in categoryOption" :key="idx" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="black-btn-outline-lg text-md mr-5" @click="goBackList">返回列表</button>
            <el-button v-permission="['update']" class="orange-btn-800-lg text-md" :disabled="disabled" @click="update">儲存變更</el-button>
        </div>
    </div>
</template>

<script>
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
import { handleErrorMessage } from "@/service/errorMessage";

export default {
    name: "EditRule",
    components: {
        LoadingComponent,
    },
    computed: {
        // 使用期間選項
        duringOption() {
            return [
                { label: "不限使用期間", value: false },
                { label: "限定使用期間", value: true },
            ];
        },
        startDateOptions() {
            const end_date = this.inputs.end_date ? new Date(this.inputs.end_date).getTime() : "";
            return {
                disabledDate(time) {
                    if (!end_date) {
                        return false;
                    }
                    return time.getTime() > end_date;
                },
            };
        },
        endDateOptions() {
            const start_date = new Date(this.inputs.start_date).getTime();
            return {
                disabledDate(time) {
                    return time.getTime() < start_date;
                },
            };
        },
        disabled() {
            const { category, end_date, start_date, text, url } = this.inputs;
            const isfillDate = end_date || start_date ? true : false;
            if (category !== null && text && url) {
                if (this.duration) {
                    if (isfillDate) {
                        return false;
                    }
                    return true;
                }
                return false;
            }
            return true;
        },
    },
    data() {
        return {
            loading: false,
            // 輸入匡狀態
            inputs: {
                category: null,
                end_date: "",
                start_date: "",
                text: "",
                url: "",
                pin: 0,
            },
            duration: false,
            categoryOption: [],
        };
    },
    methods: {
        // 更新資料
        async getData() {
            const { status, data } = await this.$api
                .GetGuidelineID(this.$route.params.ruleid)
                .then((res) => {
                    return { status: res.status, data: res.data };
                })
                .catch(() => {
                    return { status: 400, data: { message: "err" } };
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.inputs = { ...data };
                const { start_date, end_date } = this.inputs;
                this.duration = false;
                if (start_date || end_date) {
                    this.duration = true;
                }
            } else {
                this.goBackList();
            }
        },
        // 取得分類
        async getAllGategories() {
            const result = [];
            await this.$api
                .getGategories()
                .then((res) => {
                    res.data.categories.forEach((item) => {
                        result.push({
                            label: item.name,
                            value: item.id,
                        });
                    });
                    this.categoryOption = [...result];
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得分類失敗",
                    });
                });
        },
        // 編輯 API
        update() {
            this.loading = true;
            const { id, category, end_date, start_date, text, url, pin } = this.inputs;
            const req = {
                id,
                category,
                end_date,
                start_date,
                text,
                url,
                pin,
            };
            this.$api
                .UpdateGuidelines(id, req)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "編輯成功",
                    });
                })
                .catch((err) => {
                    const errorMessage = handleErrorMessage(err.response.data.errors);
                    this.$message({
                        type: "error",
                        message: errorMessage ? errorMessage : "編輯失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        // 返回列表
        goBackList() {
            this.$router.push({ name: "fighting_rules" });
        },
        changeDuration(val) {
            if (!val) {
                this.inputs.start_date = "";
                this.inputs.end_date = "";
            }
        },
        deleteItem() {
            const opts = {
                title: "刪除資料",
                message: "資料刪除後將無法復原",
            };
            this.$pop.popConfirm(opts).then(
                () => {
                    this.$api.DeleteGuideline(this.$route.params.ruleid).then(() => {
                        this.$message({
                            type: "success",
                            message: "刪除成功",
                        });
                        this.goBackList();
                    });
                },
                () => {
                    return;
                }
            );
        },
    },
    async activated() {
        await this.getAllGategories();
        this.getData();
    },
    async mounted() {
        await this.getAllGategories();
        this.getData();
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
}
.content {
    .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
    }
    .top-content {
        padding: 30px 5% 0;
        .inputs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 20px 30px;
            .input {
                width: 48%;
                padding: 10px 0px;
                .profile {
                    display: flex;
                    align-items: center;
                    .profile-img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                    }
                    .name {
                        color: #ff5733;
                        text-decoration: underline;
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .bottom-content {
        padding: 30px 5%;
        .upload-area {
            display: flex;
            padding: 20px 20px 30px;
            .left {
                max-width: 50%;
                flex: 1;
                margin-right: 30px;
            }
            .right {
                flex: 1;
            }
        }
    }
    .label {
        color: rgb(133, 130, 130);
        margin-bottom: 5px;
        font-size: 14px;
    }
}

.display-img {
    margin-top: 15px;
    position: relative;
    padding: 30px;
    .error {
        width: 24px;
        height: 24px;
        background: #000;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
::v-deep .el-upload-list {
    display: none;
}
.actions {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
